@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'simple-keyboard/build/css/index.css';

@font-face {
  font-family: 'Bosch Sans';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/font/BoschSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Bosch Sans';
  font-style: normal;
  font-weight: bold;
  src: url(./assets/font/BoschSans-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Bosch Sans';
  font-style: italic;
  src: url(./assets/font/BoschSans-RegularItalic.otf) format('opentype');
}

@font-face {
  font-family: 'Bosch Sans';
  font-style: italic;
  font-weight: bold;
  src: url(./assets/font/BoschSans-RegularItalic.otf) format('opentype');
}
html {
  background-color: #000000;
}

.page-content {
  position: absolute;
  top: 0px;
  z-index: -1;
}

h1,
h2,
h3,
h4,
h5,
p,
li,
span,
div,
iframe {
  user-select: none;
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

.full-height {
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
}

@layer base {
  h1 {
    @apply text-5xl;
    line-height: 3.5rem;
    font-family: 'Bosch Sans', sans-serif;
    font-weight: bold;
  }
  h2 {
    font-family: 'Bosch Sans', sans-serif;
    @apply text-3xl;
  }

  body {
    font-family: 'Bosch Sans', sans-serif;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
}

.box-effekt-1-off {
  animation-duration: 2s;
  animation-name: anime;
}

//---          ANIMATIONS               ---//
@keyframes anime {
  0% {
    width: 50%;
    height: 30%;
    color: transparent;
  }
  25% {
    width: 50%;
    height: 30%;
    color: transparent;
  }
  50% {
    width: 100%;
    height: 100%;
    color: transparent;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}

// --- SPINNER ANIMATION ---//
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

// Fade-In Animations for boxes on Produktweg
.fade-in-box {
  animation: fadeIn 400ms calc(var(--animation-order) * 150ms) both ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.important-pb-32 {
  padding-bottom: 8rem !important;
}

.important-pb-48 {
  padding-bottom: 12rem !important;
}

.px-18 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.text-26 {
  font-size: 1.6rem;
  line-height: 1.2;
}

.text-28 {
  font-size: 1.75rem;
  line-height: 1.2;
}

.max-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.container {
  padding-left: 2rem;
  padding-right: 2.5rem;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

// --- SALLYS STYLING --- //
.sally-wrap {
  position: relative;
  top: -3.75rem;
  display: flex;
  border-bottom: 0.5rem solid #9e2896;
  border-radius: 0px;
}

.sally-wrap-diagonal {
  position: relative;
  top: -2rem;
  display: flex;
  border-bottom: 0.5rem solid #9e2896;
  border-radius: 0px;
}

.icon-container,
.features-wrap {
  display: flex;
  flex-wrap: wrap;
}

.sally-container {
  width: 55%;
}

.sally-tipp {
  width: 7.6875rem;
  padding-right: 1rem;
}

.sally-play-icon-container {
  height: 3.975rem;
  width: 3.975rem;
  display: flex;
  background: #f7eef6;
  position: relative;
  align-items: center;
  justify-content: center;
  .sally-play-icon {
    padding: 0.8rem;
  }
}

.sally-plus-icon-container {
  height: 3.975rem;
  width: 3.975rem;
  background: #f7eef6;
  position: relative;
  .sally-plus-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    width: 50%;
  }
}

.sallys-img-container {
  width: 45%;
  display: flex;
  justify-content: end;
  img {
    height: 92%;
    object-fit: cover;
    position: absolute;
    bottom: 0px;
  }
}

// --- STYLING FOR MEDIACAROUSEL --- //
.media-carousel-container,
.highlights-carousel-container-overlay,
.media-carousel-container-overlay,
.related-carousel-container,
.related-carousel-container-diagonal,
.video-library-carousel-container,
.sally-overlay-carousel-container,
.sally-overlay-video-carousel-container {
  position: relative;
  .owl-item img {
    object-fit: cover;
  }
  .arrow {
    width: 1.2rem;
  }
  .owl-prev,
  .owl-next {
    background-color: #000000b0 !important;
    height: 4.375rem;
    width: 4.375rem;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .owl-prev {
    left: -2.5rem;
    transform: translate(-50%, -50%);
  }
  .owl-next {
    right: -7rem;
    transform: translate(-50%, -50%);
  }
}

// ------ STYLING FOR RELATED PRODUCTS CAROUSEL --------- //
.related-carousel-container {
  .owl-prev,
  .owl-next {
    top: 25%;
  }
}

// ------ STYLING FOR RELATED PRODUCTS CAROUSEL (DOUBLE CAROUSEL) --------- //
.related-carousel-container-diagonal {
  .owl-prev,
  .owl-next {
    top: 29%;
  }
  .owl-prev {
    right: -2.44rem;
  }
  .owl-next {
    right: -6.89rem;
  }
}

// ------ STYLING FOR VIDEO LIBRARY CAROUSEL --------- //
.video-library-carousel-container {
  .owl-prev,
  .owl-next {
    top: 35%;
  }
}

// ------ STYLING FOR SALLY OVERLAY CAROUSEL --------- //
.sally-overlay-carousel-container {
  .owl-prev,
  .owl-next {
    top: 31%;
  }
}

// ------ STYLING FOR SALLY OVERLAY VIDEO CAROUSEL --------- //
.sally-overlay-video-carousel-container {
  .owl-prev,
  .owl-next {
    top: 45%;
  }
}

// ------ STYLING FOR VIDEO SLIDER ------ //
.carousel-container {
  position: relative;
  .owl-nav {
    display: flex !important;
    justify-content: center;
    gap: 8rem;
  }
  .arrow {
    width: 1.2rem;
  }
  .owl-prev,
  .owl-next {
    position: relative;
    background-color: #000000b0 !important;
    height: 4.375rem;
    width: 4.375rem;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
  }
}

/* Hide Scrollbar for IE, Edge, Firefox Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/*  BOSCH CUSTOM SCROLLBAR   */
.bosch-scrollbar::-webkit-scrollbar {
  width: 0.25rem;
  height: 0;
}

.bosch-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.625rem;
}

.bosch-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 0.625rem;
}

//prevent accidental zoom actions
:root {
  touch-action: pan-x pan-y;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 2150px) {
  html {
    font-size: 32px;
  }
}

@media (min-width: 2000px) and (max-width: 2150px) {
  html {
    font-size: 24px;
  }
}

// CSS only for small mobile phones
@media (max-width: 350px) {
  .sally-wrap {
    width: 100%;
  }

  .text-small {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }

  h1 {
    font-size: 1.5rem;
    line-height: normal;
  }

  h3 {
    font-size: 0.6rem;
  }

  h4 {
    font-size: 0.9rem;
  }
}

// CSS only for mobile phones
@media (min-width: 351px) and (max-width: 680px) {
  .sally-wrap {
    width: 100%;
  }

  .text-small {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }

  h1 {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  h3 {
    font-size: 0.6rem;
  }

  h4 {
    font-size: 1.1rem;
  }
}

// CSS only for tablets
@media (min-width: 681px) and (max-width: 1024px) {
  .sally-wrap {
    width: 50%;
  }

  .text-small {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  h1 {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  h3 {
    font-size: 0.8rem;
  }

  h4 {
    font-size: 1.1rem;
  }
}

// CSS for tablets and mobile phones
@media (max-width: 1024px) {
  :root {
    touch-action: auto;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  p {
    font-size: 1rem;
  }

  span {
    font-size: 0.75rem;
  }

  .unscrollable {
    height: calc(100vh - 30rem);
    overflow: hidden;
  }

  .sally-container {
    width: 60%;
  }

  .sallys-img-container {
    width: 40%;

    img {
      height: 110%;
    }
  }

  .sally-plus-icon-container {
    height: 2.65rem;
    width: 2.65rem;
  }

  .sally-tipp {
    width: 5.5rem;
  }

  .sally-wrap {
    border-bottom: 0.3rem solid #9e2896;
    top: 0;
  }

  .sally-wrap-diagonal {
    border-bottom: 0.3rem solid #9e2896;
    margin-top: 3rem;
  }

  .sally-play-icon-container {
    height: 2.6rem;
    width: 2.6rem;
    .sally-play-icon {
      padding: 0.5rem;
    }
  }

  .sally-plus-icon-container {
    height: 2.65rem;
    width: 2.65rem;
  }

  .padding-mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .pb-mobile {
    padding-bottom: 5rem;
  }

  // --- STYLING FOR MEDIACAROUSEL --- //
  .media-carousel-container,
  .highlights-carousel-container-overlay,
  .media-carousel-container-overlay,
  .related-carousel-container,
  .related-carousel-container-diagonal,
  .video-library-carousel-container,
  .sally-overlay-carousel-container,
  .sally-overlay-video-carousel-container {
    .arrow {
      width: 0.65rem;
    }
    .owl-prev,
    .owl-next {
      height: 2.5rem;
      width: 2.5rem;
    }
    .owl-prev {
      left: -2.5rem;
    }
    .owl-next {
      right: -5rem;
    }
  }
}

// Media Query for normal tablet resolution
@media (min-width: 1025px) and (max-width: 1400px) and (max-height: 1024px) {
  :root {
    touch-action: auto;
  }

  html {
    font-size: 14px;
  }

  .unscrollable {
    height: calc(100vh - 30rem);
    overflow: hidden;
  }
}

// Media Query for normal tablet resolution in landscape
@media (min-width: 1000px) and (max-width: 1400px) and (min-height: 700px) and (max-height: 1500px) {
  // --- SALLYS STYLING --- //
  .sally-container {
    width: 75%;
  }

  .sallys-img-container {
    width: 45%;
    img {
      height: 100%;
    }
  }

  :root {
    touch-action: auto;
  }
}

// Media query for high res tablets in landscape
@media (min-width: 2000px) and (max-width: 2800px) and (max-height: 2100px) {
  :root {
    touch-action: auto;
  }

  h1 {
    font-size: 2.5rem;
  }

  .unscrollable {
    height: calc(100vh - 40rem);
    overflow: hidden;
  }

  // --- SALLYS STYLING --- //

  .sally-container {
    width: 75%;
  }

  .sally-tipp {
    width: 7.6875rem;
    padding-right: 1rem;
  }

  .sally-play-icon-container {
    height: 3.975rem;
    width: 3.975rem;
    .sally-play-icon {
      padding: 0.85rem;
    }
  }

  .sally-plus-icon-container {
    height: 3.975rem;
    width: 3.975rem;
    background: #f7eef6;
    position: relative;
    .sally-plus-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 50%;
      width: 50%;
    }
  }

  .sallys-img-container {
    width: 45%;
    img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      bottom: 0px;
    }
  }
}

// Media Query for 4k tablets
@media (min-width: 1025px) and (max-width: 2900px) and (min-height: 2000px) and (max-height: 2900px) {
  :root {
    touch-action: auto;
  }

  h1 {
    font-size: 2rem;
    line-height: 3rem;
  }

  p,
  h2,
  h3,
  h4 {
    font-size: 1.25rem;
  }

  html {
    font-size: 18px;
  }
}

// Keyboard styles
.background-blur {
  background-color: rgb(0 0 0 / 95%);
  -webkit-box-shadow: inset 0px 0px 123vw 100vh #14141433;
  box-shadow: inset 0px 0px 123vw 100vh #14141433;
}

.z-3 {
  z-index: 3;
}

.close-icon-container {
  height: 4.375rem;
  width: 4.375rem;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  background-color: #000000b0 !important;
}

.close-icon {
  width: 1.75rem;
}

.email-input {
  border-bottom: 0.125rem solid white;
  filter: brightness(1.5);
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: black;
  width: 2rem;
  height: 2rem;
  margin-right: 2rem;
  border: 0.15rem solid #fff;
  transform: translateY(-0.075rem);
  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 1.3rem;
  height: 1.3rem;
  transform: scale(0);
  transition: 100ms transform ease-in-out;
  box-shadow: inset 1rem 1rem black;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

.error-input {
  border-bottom: 0.125rem solid red !important;
}

.error {
  border: 0.125rem solid red !important;
}

input[type='checkbox']:checked.error {
  border: 0.125rem solid transparent !important;
}

.simple-keyboard {
  max-width: 100%;
}

.simple-keyboard.myTheme1 {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.simple-keyboard.hg-layout-default .hg-button.bg-darker {
  background-color: hsl(252deg 8% 12%) !important;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.hg-theme-default .hg-button {
  border: 0.0625rem solid transparent !important;
  background-color: hsl(247deg 9% 20%) !important;
  border-radius: 0.125rem;
  box-shadow: 0 0 0.1875rem -0.0625rem rgb(0 0 0 / 30%);
}

.hide {
  display: none !important;
}

.show {
  display: unset !important;
}

.simple-keyboard.myTheme1 .bg-darker .hg-activeButton,
.simple-keyboard.myTheme1 .hg-button:active,
.simple-keyboard.myTheme1 .bg-darker:active {
  background-color: rgb(86, 86, 86) !important;
  color: white;
}

.simple-keyboard.myTheme1 .shift .hg-activeButton,
.simple-keyboard.myTheme1 .shift:active,
.simple-keyboard.myTheme1 .shift {
  background-image: url('assets/icon/shift.svg') !important;
  background-size: 1rem;
  color: transparent;
  background-repeat: no-repeat !important;
  background-position: center;
}

.simple-keyboard.myTheme1 .enter .hg-activeButton,
.simple-keyboard.myTheme1 .enter:active,
.simple-keyboard.myTheme1 .enter {
  background-image: url('assets/icon/keyboard-arrow-enter-return.svg') !important;
  background-size: 1rem;
  color: transparent;
  background-repeat: no-repeat !important;
  background-position: center;
}

.simple-keyboard.myTheme1 .caps .hg-activeButton,
.simple-keyboard.myTheme1 .caps:active,
.simple-keyboard.myTheme1 .caps {
  background-image: url('assets/icon/caps.svg') !important;
  background-size: 1rem;
  color: transparent;
  background-repeat: no-repeat !important;
  background-position: center;
}

.simple-keyboard.myTheme1 .delete .hg-activeButton,
.simple-keyboard.myTheme1 .delete:active,
.simple-keyboard.myTheme1 .delete {
  background-image: url('assets/icon/backspace.svg') !important;
  background-size: 1rem;
  color: transparent;
  background-repeat: no-repeat !important;
  background-position: center;
}

.simple-keyboard.myTheme1 .right-tab .hg-activeButton,
.simple-keyboard.myTheme1 .right-tab:active,
.simple-keyboard.myTheme1 .right-tab {
  background-image: url('assets/icon/keyboard-arrow-right-tab.svg') !important;
  background-size: 1rem;
  color: transparent;
  background-repeat: no-repeat !important;
  background-position: center;
}
